import React, { useState, useEffect , useRef } from 'react'
import DataTable from 'react-data-table-component';
import { makeApiRequest } from "../AxiosServices/apiCall";
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from "react-toastify";

function Stock() {

    const Navigate = useNavigate();
    const [product, setProduct] = useState([]);
    console.log("product---", product);

    const [categoryId, setCategoryId] = useState("")
    const [categoryList, setCategoryList] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedOption, setSelectedOption] = useState([])
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    // Handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter products based on the selected category and the search term
        let filtered = product.filter(prod =>
            prod.productname.toLowerCase().includes(value.toLowerCase()) ||
            prod.category.toLowerCase().includes(value.toLowerCase()) ||
            prod.subCategory.toLowerCase().includes(value.toLowerCase())
        );

        // If a category is selected, filter by the selected category as well
        if (categoryId) {
            filtered = filtered.filter(prod =>
                prod.categoryId === categoryId
            );
        }

        setFilteredProducts(filtered);
    };


    const handleEdit = (product) => {
        console.log('product---', product);

        setSelectedProduct(product);
    };

    const columnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
            sortable: true,
            id: 'sno',
            width: '80px'
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
            id: 'category',
            width: "100px",
        },
        {
            name: 'Sub Category',
            selector: row => row.subCategory,
            sortable: true,
            id: 'subCategory',
            width: "150px",
        },
        {
            name: 'Product Name',
            selector: row => row.productname,
            sortable: true,
            id: 'productname',
            width: "450px",
        },
        {
            name: 'Stock',
            selector: row => row.quantity,
            sortable: true,
            id: 'stock',
            width: "120px",
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
            id: 'price',
            width: "120px",
        },
        {
            name: 'Discount',
            selector: row => row.discount,
            cell: row => `${row.discount == "" ? "0" : row.discount}%`,
            sortable: true,
            id: 'price',
            width: "120px",
        },
        {
            name: 'Fragrance',
            selector: row => row.shade,
            sortable: true,
            id: 'fragrance',
            width: "120px",
        },
        {
            name: 'Quantity(ml or grm)',
            selector: row => row.size,
            sortable: true,
            id: 'quantity',
            width: "120px",
        },
       
        {
            name: 'Quantity ADD',
            selector: row => row.edit,
            sortable: true,
            width: "120px",
            cell: (row) => (
                <div className="d-flex data-gap">
                    <button type="button" className="liquidity-btn-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
                        Add
                    </button>
                </div>
            ),
        },
    ];

    const formik = useFormik({
        initialValues: {
            quantity: selectedProduct?.quantity || '', // Set the initial value based on the selected product
        },
        validationSchema: Yup.object({
            quantity: Yup.string().required('Quantity is required'),
        }),

        onSubmit: async (values) => {
            const formData = {

                id: selectedProduct._id,
                quantity: values.quantity,
            };

            console.log('formData--', formData);

            try {
                setIsLoading(true);
                const params = {
                    url: `quantityUpdate`,
                    method: "POST",
                    data: formData,
                };

                const response = await makeApiRequest(params);
                if (response.status === true) {
                    setIsLoading(false);
                    toast.success(response.message);
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                } else {
                    toast.warn(response.message);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        }
    });

    const getProductdatas = async () => {
        try {
            let params = {
                url: `getstockproduct`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const productList = response.data
                setProduct(productList);
                setFilteredProducts(productList);
            } else {
                setProduct([]);
                setFilteredProducts([])
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const fetchCategorieList = async () => {
        try {
            let params = {
                url: "getcategories",
                method: "GET"
            };
            const response = await makeApiRequest(params);
            if (response.status) {
                const { data: categoriesList } = response;
                setCategoryList(categoriesList);

            } else {
                setCategoryList([]);
            }
        } catch (error) {
            toast.error("Something Went Wrong....", error);
        }
    };

    // const handleSelectSkinCare = (selectedValue) => {
    //     const { value, id } = selectedValue;
    //     setSelectedOption(selectedValue);
    //     setCategoryId(id);
    //     setSelectedCategory(value);
    //     const filtered = product.filter(prod => prod.categoryId === id);
    //     // console.log('filteredProducts==', filtered);
    //     setFilteredProducts(filtered);
    // };


    const handleSelectSkinCare = (selectedValue) => {
        const { value, id } = selectedValue;
        console.log("selectedValue-----", selectedValue);

        setSelectedOption(selectedValue);
        setCategoryId(id);
        setSelectedCategory(value);

        if (id) {
            const filtered = product.filter(prod => prod.categoryId === id);
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(product); // Display all products if no category is selected
        }
    };


    useEffect(() => {
        getProductdatas();
        fetchCategorieList();
    }, [])

    const skinCareOptions = categoryList.map((data) => {
        return {
            value: data.name,
            label: data.name,
            id: data._id,

        }
    })

    const clearForm = () => {
        setSelectedProduct("");
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#e98d9d' : state.isFocused ? '#e3c8cc' : null,
            color: state.isSelected ? 'white' : 'black',
        }),
    };

    return (
        <div className='App table-1'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-userlist min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='fw-bold'>Stock</h2>
                            <div className='d-flex justify-content-between align-items-baseline'>
                                <div className="col-sm-4 mt-4">
                                    <Select
                                        options={skinCareOptions}
                                        value={selectedOption}
                                        onChange={handleSelectSkinCare}
                                        placeholder="Select Category"
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="col-sm-4 mt-4">
                                    <input
                                        type="text"
                                        className="form-control input-text-2"
                                        placeholder="Search Products"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>

                            <div className='liquidity-table-1 mt-3'>
                                <DataTable
                                    columns={columnsone}
                                    data={filteredProducts}
                                    theme="solarized"
                                    defaultSortAsc={true}
                                    pagination
                                    paginationTotalRows={filteredProducts.length}
                                    paginationPerPage={rowsPerPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                    onChangePage={handlePageChange}
                                    highlightOnHover
                                    dense
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for editing quantity */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit Quantity</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { clearForm(); }}></button>
                        </div>
                        <div className="modal-body">
                            <div className='custom-stock-modal'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-3 row">
                                        <label htmlFor="quantity" className="col-sm-3 col-form-label">Quantity</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="number"
                                                className="form-control input-text-2"
                                                id="quantity"
                                                name="quantity"
                                                value={formik.values.quantity}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.quantity && formik.errors.quantity ? (
                                                <div className="text-danger">{formik.errors.quantity}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <button className='liquidity-btn-1' type="submit" disabled={isLoading}>
                                            {isLoading ? 'Updating...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stock