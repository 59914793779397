import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { makeApiRequest } from "../AxiosServices/apiCall";


function Sellproduct() {
    const [sellproductlist, setSellProductlist] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const columnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
            sortable: true,
            width: "80px",
        },
        {
            name: 'Category',
            selector: row => row.sellProductList.category,
            sortable: true,
            width: "200px",
        },
        {
            name: 'SubCategory',
            selector: row => row.sellProductList.subCategory,
            sortable: true,
            width: "250px",
        },

        {
            name: 'Product Name',
            selector: row => row.productname,
            sortable: true,
            width: "400px",
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            width: "150px",
        },

    ];

    const getSellProduct = async () => {
        try {
            let params = {
                url: `sellproduct`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const SellProductData = response.data;
                setSellProductlist(SellProductData);
            } else {
                setSellProductlist([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        getSellProduct();
    }, [])


    const filteredSellProduct = sellproductlist.filter((sell) =>
        sell.sellProductList?.category?.toLowerCase().includes(searchQuery.toLowerCase())
        || sell.sellProductList?.subCategory?.toLowerCase().includes(searchQuery.toLowerCase())
        || sell.productname?.toLowerCase().includes(searchQuery.toLowerCase())
    );



    return (
        <div className='App table-1'>
            <div className='container-fluid'>
                <div className='custom-userlist min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='custom-blog-inside'>
                                <h2 className='fw-bold'>Sold Product</h2>
                                <div className='custom-blog-search d-lg-flex align-items-baseline'>
                                    <input
                                        type="text"
                                        id="inputPassword6"
                                        class="form-control input-text-2"
                                        aria-describedby="passwordHelpInline"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='liquidity-table-1 mt-3'>
                                <DataTable
                                    columns={columnsone}
                                    data={filteredSellProduct}
                                    theme="solarized"
                                    defaultSortAsc={true}
                                    pagination
                                    paginationTotalRows={sellproductlist.length}
                                    paginationPerPage={rowsPerPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                    onChangePage={handlePageChange}
                                    highlightOnHover
                                    dense />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}





export default Sellproduct;
