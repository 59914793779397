import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeApiRequest } from "../AxiosServices/apiCall"

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const fetchData = async () => {
        try {
            let adminToken = localStorage.getItem('token');
            let params = {
                url: "admin-auth",
                method: "POST",
            }
            let response = await makeApiRequest(params);
            if (response.status) {
            } else {
                localStorage.clear()
                setIsLoggedIn(false);
                return navigate('/');
            }
        } catch (error) {
            console.log("An error occurred:", error.message);
        }
    };

    const checkUserToken = () => {
        const userToken = localStorage.getItem('token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/');
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
        fetchData()
    },);

    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute