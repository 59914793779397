import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeApiRequest } from "../AxiosServices/apiCall";
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';



function Homepage() {
    const navigate = useNavigate()
    const [showPagination, setShowPagination] = useState(true);
    const [homecontent, setHomecontent] = useState([])
    const [originalHomeContent, setOriginalHomeContent] = useState([]); // Store original data

    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isListView, setIsListView] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [trendingcontent, setTrendingcontent] = useState([])
    const [originalTrendingContent, setOriginaltrendingContent] = useState([]);
    const [trendingSearchTerm, setTrendingSearchTerm] = useState("");
    const [trendingcurrentPage, setTrendingCurrentPage] = useState(1);
    const [trendingrowsPerPage, setTrendingRowsPerPage] = useState(10);


    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        if (value === "") {
            // Reset to original data if search field is cleared
            setHomecontent(originalHomeContent);
        } else {
            // Filter based on category name
            const filtered = originalHomeContent.filter((prod) =>
                prod.categoryName.toLowerCase().includes(value.toLowerCase())
            );
            setHomecontent(filtered);
        }
    };

    const handleTrendingSearch = (event) => {
        const value = event.target.value;
        setTrendingSearchTerm(value);

        if (value === "") {
            // Reset to original data if search field is cleared
            setTrendingcontent(originalTrendingContent);
        } else {
            // Filter based on category name
            const filtered = originalTrendingContent.filter((prod) =>
                prod.category.toLowerCase().includes(value.toLowerCase()) || 
            prod.subCategory.toLowerCase().includes(value.toLowerCase())
            );
            setTrendingcontent(filtered);
        }
    };


    // const handleViewToggle = () => {
    //     setIsListView(!isListView);
    // };
    const [showButton, setShowButton] = useState(false);

    // Handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
    };
    const handleTrendingPageChange = page =>{
        setTrendingCurrentPage(page);
    }
    // const handleButtonClick = () => {
    //     setShowPagination(!showPagination);
    // };

    // const handleFilterChange = (columnId) => {
    //     setSelectedColumn(columnId);
    // };

    const gethomecontent = async () => {
        try {
            let params = {
                url: `gethomecontent`,   // Assuming this is the correct API endpoint
                method: 'GET',
            };

            let response = await makeApiRequest(params);  // Assuming makeApiRequest is a helper function that sends the API request
            console.log("response", response);

            if (response.status === true) {
                const homecontent = response.data;
                console.log('homecontent---', homecontent);
                setHomecontent(homecontent);
                setOriginalHomeContent(homecontent); // Save the original data
                // Assuming setHomecontent is a state setter function
            } else {
                console.log('Error:', response.message || 'Unknown error');
                setHomecontent([]);
                setOriginalHomeContent([]);

            }
        } catch (error) {
            console.log('API request failed:', error.message || error);
            setHomecontent([]);
            setOriginalHomeContent([]);
            // Clear content in case of an error
        } finally {
            setLoading(false);  // Stop the loader after fetching data or error
        }
    };

    const getTrendingcontent = async () => {
        try {
            let params = {
                url: `getTrendingcontent`,   // Assuming this is the correct API endpoint
                method: 'GET',
            };

            let response = await makeApiRequest(params);  // Assuming makeApiRequest is a helper function that sends the API request
            console.log("getTrendingcontent---", response);

            if (response.status === true) {
                const trendingcontent = response.data;
                console.log('trendingcontent---', trendingcontent);
                setTrendingcontent(trendingcontent);
                setOriginaltrendingContent(trendingcontent); // Save the original data
                // Assuming setHomecontent is a state setter function
            } else {
                console.log('Error:', response.message || 'Unknown error');
                setTrendingcontent([]);
                setOriginaltrendingContent([]);

            }
        } catch (error) {
            console.log('API request failed:', error.message || error);
        } finally {
            setLoading(false);  // Stop the loader after fetching data or error
        }
    };


    useEffect(() => {
        gethomecontent();
        getTrendingcontent();
    }, [])

    const convertToIST = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-IN', options);
    }

    const Deletecategorycontent = async (row) => {
        try {
            const swalResult = await Swal.fire({
                title: "Are you sure you want to Delete?",
                icon: "question",
                iconHtml: "?",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showCancelButton: true,
                showCloseButton: true
            });
            if (swalResult.isConfirmed) {
                let datas = {
                    categorycontentid: row._id
                }
                let params = {
                    url: `Categorycontentdelete`,
                    method: 'POST',
                    data: datas
                };
                let response = await makeApiRequest(params)
                    .then((response) => {
                        if (response.status === true) {
                            toast.success(response.message);
                            gethomecontent();
                        } else {
                            toast.warning(response.message);
                        }

                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            }

        } catch (error) {
            console.log("error-",error);
        }

    };


    const DeleteTrendingcategorycontent = async (row) => {
        try {
            const swalResult = await Swal.fire({
                title: "Are you sure you want to Delete?",
                icon: "question",
                iconHtml: "?",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showCancelButton: true,
                showCloseButton: true
            });
            if (swalResult.isConfirmed) {
                let datas = {
                    subcategorycontentid: row._id
                }
                let params = {
                    url: `deletTrendingCat`,
                    method: 'POST',
                    data: datas
                };
                let response = await makeApiRequest(params)
                    .then((response) => {
                        if (response.status === true) {
                            toast.success(response.message);
                            getTrendingcontent();
                        } else {
                            toast.warning(response.message);
                        }

                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            }

        } catch (error) {
            console.log("error-",error);
        }
    };

    const filteredBlogs = homecontent.filter((content) => {
        const descriptionText = content.categoryDescription
            ? content.categoryDescription.replace(/<\/?[^>]+(>|$)/g, "")
            : ""; // Remove HTML tags from categoryDescription

        return content.categoryName?.toLowerCase().includes(searchQuery.toLowerCase())
            || content.categoryImage?.toLowerCase().includes(searchQuery.toLowerCase())
            || descriptionText.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const filteredTrending = trendingcontent.filter((content) => {
        
        return content.category?.toLowerCase().includes(searchQuery.toLowerCase())
        || content.subCategory.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const columnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
            sortable: true,
            id: 'sno',
            width: '80px'
        },
        {
            name: 'Category Name',
            selector: row => row.categoryName,  // Updated to match your data
            sortable: true,
            id: 'categoryName',
            width: '200px'
        },
        {
            name: 'Category Description',
            selector: row => row.categoryDescription,


            sortable: true,
            id: 'categoryDescription',
            width: '250px',
            cell: row => <div dangerouslySetInnerHTML={{ __html: row.categoryDescription }} />

        },
        {
            name: 'Category Image',
            selector: row => (
                <div className='custom-img-pre'>
                    <img style={{ width: "50px" }} src={row.categoryImage} alt="Category" />
                </div>
            ),
            sortable: false,  // Image columns are typically not sortable
            id: 'categoryImage',
            width: '100px'
        },
        {
            name: 'Date',
            selector: row => convertToIST(row.createdAt),  // Assuming convertToIST formats the date
            sortable: true,
            id: 'createdAt',
            width: '250px'
        },
        {
            name: 'Action',
            selector: row => (
                <div className='ms-1 d-flex'>
                    <p onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
                        <FaEdit className='me-2' />
                    </p>
                    <p onClick={() => Deletecategorycontent(row)} style={{ cursor: 'pointer' }}>
                        <RiDeleteBin6Line />
                    </p>
                </div>
            ),
            sortable: false,  // Actions are typically not sortable
            id: 'action',
            width: '100px'
        }
    ];

    const trendingColumnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + (trendingcurrentPage - 1) * trendingrowsPerPage,
            sortable: true,
            id: 'sno',
            width: '80px'
        },
        {
            name: 'Category',
            selector: row => row.category,  // Updated to match your data
            sortable: true,
            id: 'categoryName',
            width: '200px'
        },
        {
            name: 'SubCategory',
            selector: row => row.subCategory,  // Updated to match your data
            sortable: true,
            id: 'subCategoryName',
            width: '200px'

        },
        {
            name: 'Category Video',
            selector: row => (
                <div className='custom-video-pre'>
                    {row.media ? (
                        <video style={{ width: "50px" }} controls>
                            <source src={row.media} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <span>No Video Available</span>
                    )}
                </div>
            ),
            sortable: false,  // Video columns are typically not sortable
            id: 'categoryVideo',
            width: '150px'  // Adjust width as needed
        },
        {
            name: 'Date',
            selector: row => convertToIST(row.createdAt),  // Assuming convertToIST formats the date
            sortable: true,
            id: 'createdAt',
            width: '250px'
        },
        {
            name: 'Action',
            selector: row => (
                <div className='ms-1 d-flex'>
                    <p onClick={() => handleTrendingEdit(row)} style={{ cursor: 'pointer' }}>
                        <FaEdit className='me-2' />
                    </p>
                    <p onClick={() => DeleteTrendingcategorycontent(row)} style={{ cursor: 'pointer' }}>
                        <RiDeleteBin6Line />
                    </p>
                </div>
            ),
            sortable: false,  // Actions are typically not sortable
            id: 'action',
            width: '100px'
        }
    ];

    const handleEdit = async (row) => {
        console.log("row-----", row);
        navigate(`/edithomecontent/${row._id}`, { state: { CategoryData: row } })
    }

    const handleTrendingEdit = async (row) => {
        console.log("row-----", row);
        navigate(`/editTrendingcontent/${row._id}`, { state: { CategoryData: row } })
    }


    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className='App'>

            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-blog min-vh-100 text-white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-blog-inside'>
                                    <h2 className='fw-bold text-dark'>Home Content</h2>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            className="form-control input-text-2"
                                            placeholder="Search Products"
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                                <a href='/addhomecontent'><button className='liquidity-btn-1'>ADD HomeContent</button></a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='liquidity-table-1 mt-3'>
                                    <div>
                                            <DataTable
                                                columns={columnsone}
                                                data={filteredBlogs}
                                                theme="solarized"
                                                defaultSortAsc={true}
                                                pagination={showPagination}
                                                paginationTotalRows={homecontent.length}
                                                paginationPerPage={rowsPerPage}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                                onChangePage={handlePageChange}
                                                highlightOnHover
                                                dense
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showButton && (
                            <button onClick={scrollToTop} id="scrollToTopBtn" title="Go to top">
                                &#8679;
                            </button>
                        )}
                    </div>


                    {/* TRENDING CATEGORIES */}

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-blog-inside mt-3'>
                                    <h2 className='fw-bold text-dark'>TRENDING CATEGORIES</h2>
                                    <div className="col-sm-4 mt-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Products"
                                            value={trendingSearchTerm}
                                            onChange={handleTrendingSearch}
                                        />
                                    </div>
                                </div>
                                <a href='/addCategoriesContent'><button className='liquidity-btn-1'>ADD TRENDING CATEGORIES</button></a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='liquidity-table-1 mt-3'>
                                    <div>
                                            <DataTable
                                                columns={trendingColumnsone}
                                                data={filteredTrending}
                                                theme="solarized"
                                                defaultSortAsc={true}
                                                pagination={showPagination}
                                                paginationTotalRows={trendingcontent.length}
                                                paginationPerPage={trendingrowsPerPage}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                onChangeRowsPerPage={(newRowsPerPage) => setTrendingRowsPerPage(newRowsPerPage)}
                                                onChangePage={handleTrendingPageChange}
                                                highlightOnHover
                                                dense
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showButton && (
                            <button onClick={scrollToTop} id="scrollToTopBtn" title="Go to top">
                                &#8679;
                            </button>
                        )}
                    </div>


                </div>
            </div>
        </div>

    )
}

export default Homepage