import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dashboard from './Dashboard';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoMdRemoveCircleOutline } from "react-icons/io";



function EditBlog() {
    const location = useLocation();
    const { blogData } = location.state || {};
    const [category, setCategory] = useState([]);
    const { id } = useParams();
    const quillRef = useRef(null);
    const Navigate = useNavigate()
    const [mainImagePreview, setMainImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);


    const { handleChange, handleSubmit, setFieldTouched, setFieldValue, values, setValues, errors, touched, validateForm } = useFormik({
        initialValues: {
            BlogTitle: blogData?.blogTitle || '',
            BlogType: blogData?.blogType || '',
            BlogDescription: blogData?.blogDescription || '',
            Image: blogData?.blogImage || ''
        },

        validationSchema: Yup.object().shape({
            BlogTitle: Yup.string().required('*Blog Title is required'),
            BlogType: Yup.string().required('*Blog Type is required'),
            BlogDescription: Yup.string().required('*Blog Description is required'),
            Image:  Yup.mixed()
                    .required('*A file is required')
                    .test('fileType', '*Unsupported file format. Only JPEG, PNG, and JPG are allowed', value => !value || (value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)))
                    .test('fileSize', '*File is too large. Maximum size is 2 MB', value => !value || (value && value.size <= 2 * 1024 * 1024))
        }),
        onSubmit: async (values, { setFieldError }) => {
            console.log("values===", values);

            await validateForm();

            const div = document.createElement('div');
            div.innerHTML = values.BlogDescription;
            const textContent = div.textContent || div.innerText || '';

            if (textContent.trim() === '') {
                setFieldError('BlogDescription', '*Blog Description is required');
                return;
            }

            const formData = new FormData();
            formData.append('id', id);
            formData.append('BlogTitle', values.BlogTitle);
            formData.append('BlogType', values.BlogType);
            formData.append('BlogDescription', values.BlogDescription);
            formData.append('BlogImage', values.Image);
            try {
                setLoading(true);
                const params = {
                    url: `blogUpdate`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };
                const response = await makeApiRequest(params);
                if (response.status === true) {
                    setLoading(false);
                    toast.success(response.message);
                    setTimeout(() => {
                        Navigate('/dashboard/blogs')
                    }, 1000);
                } else {
                    setLoading(false);
                    toast.warn(response.message);
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    });

    const getCategories = async () => {
        try {
            let params = {
                url: `getcategories`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);

            if (response.status === true) {
                setCategory(response.data);
            } else {
                setCategory([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (blogData) {
            setValues({
                BlogTitle: blogData.blogTitle,
                BlogType: blogData.blogType,
                BlogDescription: blogData.blogDescription,
                Image: blogData.blogImage
            });

            if (quillRef.current) {
                quillRef.current.getEditor().root.innerHTML = blogData.blogDescription;
            }
            setMainImagePreview(blogData.blogImage || null);
        }
    }, [blogData, setValues]);

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0]
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setMainImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFieldValue('Image', file);
        }
    };

    const handleRemoveImage = () => {
        setMainImagePreview(null);
        setFieldValue('Image', null);

        document.getElementById('Image').value = '';
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-addblog min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <div className='custom-inside-addblog'>
                                        <div className='row justify-content-center align-items-center'>
                                            <div className='col-lg-10'>
                                                <h2><a href='/dashboard/blogs'><IoArrowBackCircleOutline className='me-2' style={{ color: "rgb(235 138 152)" }} /></a> Edit Blogs</h2>
                                                <div className='custom-addblogs-1'>
                                                    <form >

                                                        <div className="mb-3 row">
                                                            <label className="col-form-label col-sm-3 text-end" htmlFor="BlogType">Blog Type</label>
                                                            <div className='col-sm-9'>
                                                                <select
                                                                    className="form-select input-text-2"
                                                                    id="BlogType"
                                                                    name="BlogType"
                                                                    value={values.BlogType}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="" disabled>Select Blog Type</option>
                                                                    {category.map((i, data) => (
                                                                        <option key={data} value={i.name}>
                                                                            {i.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.BlogType && touched.BlogType && <div className="text-danger">{errors.BlogType}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="BlogTitle" className="col-sm-3 col-form-label text-end">Blog Title</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-text-2"
                                                                    id="BlogTitle"
                                                                    name="BlogTitle"
                                                                    value={values.BlogTitle}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.BlogTitle && touched.BlogTitle && <div className="text-danger">{errors.BlogTitle}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label className="col-sm-3 col-form-label text-end">Blog Description</label>
                                                            <div className="col-sm-9 custom-edit-1">
                                                                <ReactQuill
                                                                    className="custom-quill"
                                                                    ref={quillRef}
                                                                    style={{ width: '100%' }}
                                                                    value={values.BlogDescription}
                                                                    onChange={(value) => {
                                                                        setFieldValue('BlogDescription', value);
                                                                    }}
                                                                    modules={modules}
                                                                    placeholder="Write something..."
                                                                />
                                                                {errors.BlogDescription && touched.BlogDescription && <div className="text-danger">{errors.BlogDescription}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="Image" className="col-sm-3 col-form-label text-end">Blog Image</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-text-2"
                                                                    id="Image"
                                                                    name="Image"
                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                    onChange={handleFileChange}
                                                                />
                                                                {errors.Image && touched.Image && <div className="text-danger">{errors.Image}</div>}
                                                            </div>
                                                            <div className='row'>
                                                                <label htmlFor="Image" className="col-sm-3 col-form-label text-end"></label>
                                                                <div className='image-preview mt-3 col-lg-8 ms-1'>
                                                                    {mainImagePreview && (
                                                                        <div className='preview-container'>
                                                                            <img src={mainImagePreview} alt="Preview" className='img-thumbnail' />
                                                                            <button type="button" className="btn btn-danger btn-sm"
                                                                                onClick={handleRemoveImage}
                                                                            >
                                                                                <IoMdRemoveCircleOutline />
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='text-center'>
                                                            {loading == true ? (
                                                                <button className="liquidity-btn-1" >
                                                                    <Spinner animation="border" />
                                                                </button>
                                                            ) : (
                                                                <button className='liquidity-btn-1 mt-4' type="submit" onClick={handleSubmit}>Update</button>
                                                            )}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditBlog;
