import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './Dashboard';
import Select from 'react-select';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoMdRemoveCircleOutline } from "react-icons/io";


function Addhomecontent() {
    const quillRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);  // Set initial loading state to false
    const [categoryimage, setCategoryimage] = useState(null);
    const [categoryId, setCategoryId] = useState("")
    const [categoryList, setCategoryList] = useState([])
    const [product, setProduct] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedOption, setSelectedOption] = useState([])
    const [selectedCategory, setSelectedCategory] = useState();
    const [viewImage, setViewImage] = useState([])


    // Formik setup with validation schema
    const formik = useFormik({
        initialValues: {
            categoryid: "",
            categoryname: "",
            categorydescription: "",
            categoryimage: null,
        },
        validationSchema: Yup.object({
            categoryid: Yup.string().required('*Category ID is required'),
            categoryname: Yup.string().required('*Category Name is required'),
            categorydescription: Yup.string().required('*Category Description is required'),
            categoryimage: Yup.mixed()
                .required('*Category Image is required')
                .test(
                    "fileSize",
                    "*Image size is too large (max 2MB)",
                    value => !value || (value && value.size <= 2 * 1024 * 1024) // max 2MB
                )
                .test(
                    "fileFormat",
                    "*Unsupported Format (allowed: jpeg, jpg, png)",
                    value => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)
                ),
        }),
        onSubmit: async (values) => {
            console.log('Form values:', values); // Ensure form values are correctly populated

            const formData = new FormData();
            formData.append('categoryid', values.categoryid);
            formData.append('categoryname', values.categoryname);
            formData.append('categorydescription', values.categorydescription);
            formData.append('categoryImage', values.categoryimage);
            console.log("formadata", formData);
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value); // Make sure that categoryimage is appended correctly
            }

            try {
                setLoading(true);
                const params = {
                    url: `addhomecontent`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };
                const response = await makeApiRequest(params);
                if (response) {
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate('/dashboard/homecontent')
                    }, 1000);
                } else {
                    toast.warn(response.message);
                }
            } catch (error) {
                toast.error('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        }
    });


    const fetchCategorieList = async () => {
        try {
            let params = {
                url: "getcategories",
                method: "GET"
            };
            const response = await makeApiRequest(params);
            if (response.status) {
                const { data: categoriesList } = response;
                setCategoryList(categoriesList);
            } else {
                setCategoryList([]);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleSelectSkinCare = (selectedValue) => {
        const { value, id } = selectedValue;
        setSelectedOption(selectedValue);
        setCategoryId(id);
        setSelectedCategory(value);
        formik.setFieldValue('categoryid', id);
        formik.setFieldValue('categoryname', value);
        const filtered = product.filter(prod => prod.categoryId === id);
        // console.log('filteredProducts==', filtered);
        setFilteredProducts(filtered);
    };

    useEffect(() => {
        fetchCategorieList();
    }, [])

    const skinCareOptions = categoryList.map((data) => {
        return {
            value: data.name,
            label: data.name,
            id: data._id
        }
    })

    // Simulate fetching the data (e.g., from an API) and set initial values


    // Handling the file input and image preview
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        // console.log(file, "file");

        if (file) {

            if (file.type.startsWith('image/')) {
                setCategoryimage(URL.createObjectURL(file)); 
                formik.setFieldValue('categoryimage', file); 
            } else {
                toast.warn('Please select an image file.');
                document.getElementById('categoryimage').value = '';
            }
        }
    };

    const handleRemoveImage = () => {
        setCategoryimage(null); 
        formik.setFieldValue('categoryimage', null); 
        document.getElementById('categoryimage').value = '';
    };

    const handleQuillChange = (content) => {
        formik.setFieldValue('categorydescription', content);
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    };

    return (

        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='col-lg-2'>
                    <Dashboard />
                </div>
                <div className='custom-addcategory min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <div className='custom-inside-addcategory'>
                                        <div className='row '>
                                            <div className='col-lg-10'>
                                                <h2><a href='/dashboard/homecontent'><IoArrowBackCircleOutline className='me-2' style={{ color: "rgb(235 138 152)" }} /></a>Add HomeContent</h2>
                                                <div className="col-sm-4 mt-4">

                                                </div>
                                                <div className='custom-addcategory-1'>
                                                    <form onSubmit={formik.handleSubmit}>

                                                        <div className="mb-3 row">
                                                            <label htmlFor="category" className="col-sm-3 col-form-label text-end">Category </label>
                                                            <div className="col-sm-9">
                                                                <Select
                                                                    options={skinCareOptions}
                                                                    value={selectedOption}
                                                                    onChange={handleSelectSkinCare}
                                                                    placeholder="Select Category"
                                                                />
                                                                {formik.touched.categoryname && formik.errors.categoryname ? (
                                                                    <div className="text-danger">{formik.errors.categoryname}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="categoryimage" className="col-sm-3 col-form-label text-end">Category Image</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-text-2"
                                                                    id="categoryimage"
                                                                    name="categoryimage"
                                                                    onChange={handleImageChange}
                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                />
                                                                {formik.touched.categoryimage && formik.errors.categoryimage ? (
                                                                    <div className="text-danger">{formik.errors.categoryimage}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className='row'>

                                                                <label htmlFor="categoryimage" className="col-sm-3 col-form-label text-end"></label>
                                                                <div className='image-preview mt-3 col-lg-8 ms-1'>
                                                                    {categoryimage && (
                                                                        <div className='preview-container'>
                                                                            <img src={categoryimage} alt="Preview" className='img-thumbnail' />

                                                                            <button type="button" className="btn btn-danger btn-sm" onClick={handleRemoveImage}>
                                                                            <IoMdRemoveCircleOutline />
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="categorydescription" className="col-sm-3 col-form-label text-end">Category Description</label>
                                                            <div className="col-sm-9 custom-edit-1">
                                                                <ReactQuill
                                                                    className="custom-quill"
                                                                    ref={quillRef}
                                                                    style={{ width: '100%' }}
                                                                    value={formik.values.categorydescription}
                                                                    onChange={handleQuillChange}
                                                                    modules={modules}
                                                                    placeholder="Write something..."
                                                                />
                                                                {formik.touched.categorydescription && formik.errors.categorydescription ? (
                                                                    <div className="text-danger">{formik.errors.categorydescription}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className='text-center'>
                                                            {loading == true ? (
                                                                <button className="liquidity-btn-1" disabled>
                                                                    <Spinner animation="border" />
                                                                </button>
                                                            ) : (
                                                                <button className='liquidity-btn-1 mt-4' type="submit">Add</button>
                                                            )}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Addhomecontent;
