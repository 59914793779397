import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import { makeApiRequest } from "../AxiosServices/apiCall";
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For validation schema
import { Spinner } from 'react-bootstrap';


function Socialmedia() {
    const [initialValues, setInitialValues] = useState({
        facebook: '',
        twitter: '',
        whatsapp: '',
        instagram: '',
        sitename: '',
        siteyear: '',
    });
    const [loading, setLoading] = useState(false);


    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true, // Allow formik to reinitialize when initialValues change

        validationSchema: Yup.object({
            facebook: Yup.string().required('*Facebook link is required'),
            twitter: Yup.string().required('*Twitter link is required'),
            whatsapp: Yup.string().required('*WhatsApp link is required'),
            instagram: Yup.string().required('*Instagram link is required'),
            sitename: Yup.string().required('*Site Name is required'),
            siteyear: Yup.string()
                .matches(/^[0-9]+$/, '*Site Year must contain only numbers')
                .required('*Site Year is required')
        }),

        onSubmit: async (values, { resetForm }) => {
            const params = {
                url: "addSocialMedialinks",
                method: "POST",
                data: values,
            };

            try {
                setLoading(true);
                const resp = await makeApiRequest(params);
                if (resp.status === true) {
                    setLoading(false);
                    toast.success("Social media links updated successfully!");
                    resetForm(); // Reset form after successful submission
                    getSocialMedia()
                } else {
                    setLoading(false);
                    toast.error("Failed to update social media links.");
                }
            } catch (error) {
                console.log("error", error);

            }
        },
    });

    // Fetching social media links when the component mounts
    useEffect(() => {
        getSocialMedia();  // Fetch the data when the component mounts
    }, []);

    const getSocialMedia = async () => {
        try {
            const params = {
                url: "getSocialMedialinks",
                method: 'GET',
            };

            // API call to fetch social media links
            const response = await makeApiRequest(params);

            if (response.status === true) {

                const { facebook_link, instragram_link, siteName, whatsapp_link, twitter_link, siteyear } = response.data;

                setInitialValues({
                    facebook: facebook_link,
                    twitter: twitter_link,
                    whatsapp: whatsapp_link,
                    instagram: instragram_link,
                    sitename: siteName,
                    siteyear: siteyear,
                });

            } else {
                setInitialValues({
                    facebook: '',
                    twitter: '',
                    whatsapp: '',
                    instagram: '',
                    sitename: '',
                    siteyear: '',
                });
            }
        } catch (error) {
            console.log('Error fetching social media links:', error);
        }
    };

    return (
        <div className='App'>
            <ToastContainer />

            <div className='custom-social-media'>
                <div className='container'>
                    <div className='custom-inside-social'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h2 className='fw-bold'>Social Media</h2>
                                <div className='row'>
                                    <div className='col-lg-9'>
                                        <div className='custom-social-setting'>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="mb-3">
                                                    <label htmlFor="facebook" className="form-label lable-color">Facebook</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control input-text-2 ${formik.touched.facebook && formik.errors.facebook ? 'is-invalid' : ''}`}
                                                        id="facebook"
                                                        value={formik.values.facebook}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.facebook && formik.errors.facebook ? (
                                                        <div className="invalid-feedback">{formik.errors.facebook}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="whatsapp" className="form-label lable-color">WhatsApp</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control input-text-2 ${formik.touched.whatsapp && formik.errors.whatsapp ? 'is-invalid' : ''}`}
                                                        id="whatsapp"
                                                        value={formik.values.whatsapp}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.whatsapp && formik.errors.whatsapp ? (
                                                        <div className="invalid-feedback">{formik.errors.whatsapp}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="twitter" className="form-label lable-color">Twitter</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control input-text-2 ${formik.touched.twitter && formik.errors.twitter ? 'is-invalid' : ''}`}
                                                        id="twitter"
                                                        value={formik.values.twitter}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.twitter && formik.errors.twitter ? (
                                                        <div className="invalid-feedback">{formik.errors.twitter}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="instagram" className="form-label lable-color">Instagram</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control input-text-2 ${formik.touched.instagram && formik.errors.instagram ? 'is-invalid' : ''}`}
                                                        id="instagram"
                                                        value={formik.values.instagram}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.instagram && formik.errors.instagram ? (
                                                        <div className="invalid-feedback">{formik.errors.instagram}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="sitename" className="form-label lable-color">Site Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control input-text-2 ${formik.touched.sitename && formik.errors.sitename ? 'is-invalid' : ''}`}
                                                        id="sitename"
                                                        value={formik.values.sitename}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.sitename && formik.errors.sitename ? (
                                                        <div className="invalid-feedback">{formik.errors.sitename}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="siteyear" className="form-label lable-color">Site Year</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control input-text-2 ${formik.touched.siteyear && formik.errors.siteyear ? 'is-invalid' : ''}`}
                                                        id="siteyear"
                                                        value={formik.values.siteyear}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.siteyear && formik.errors.siteyear ? (
                                                        <div className="invalid-feedback">{formik.errors.siteyear}</div>
                                                    ) : null}
                                                </div>
                                                <div className='text-center'>
                                                    {loading == true ? (
                                                        <button className="liquidity-btn-1" disabled>
                                                            <Spinner animation="border" />
                                                        </button>
                                                    ) : (
                                                        <button className='liquidity-btn-1 mt-4' type="submit">Add</button>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Socialmedia;
