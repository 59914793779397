import React, { useState, useEffect, useRef } from 'react';
import Dashboard from './Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { IoArrowBackCircleOutline } from "react-icons/io5";


function Faqeditblogs() {

    const Navigate = useNavigate()
    const location = useLocation();
    const { id } = useParams();
    const { faqdata } = location.state || {};
    const quillRef = useRef(null);

    const { handleChange, handleSubmit, setFieldTouched, setFieldValue, values, setValues, errors, touched, validateForm } = useFormik({
        initialValues: {
            faqTitle: faqdata?.faqTitle || '',
            faqDescription: faqdata?.faqDescription || '',
        },
        validationSchema: Yup.object().shape({
            faqTitle: Yup.string().required('*Faq Title is required'),
            faqDescription: Yup.string().required('*Faq Description is required'),
        }),
        onSubmit: async (values, { setFieldError }) => {
            await validateForm();

            const div = document.createElement('div');
            div.innerHTML = values.faqDescription;
            const textContent = div.textContent || div.innerText || '';

            if (textContent.trim() === '') {
                setFieldError('faqDescription', '*Faq Description is required');
                return;
            }

            const formData = new FormData();
            formData.append('id', id);
            formData.append('faqTitle', values.faqTitle);
            formData.append('faqDescription', values.faqDescription);
            formData.append('type', 'update');

            try {
                const params = {
                    url: `faqUpdate`,
                    method: "POST",
                    data: formData,

                };
                const response = await makeApiRequest(params);
                if (response.status === true) {
                    toast.success(response.message);
                    setTimeout(() => {
                        Navigate('/dashboard/faq')
                    }, 1000);
                } else {
                    toast.warn(response.message);
                }
            } catch (error) {
                toast.error('Error: ' + error.message);
            }
        }
    });

    useEffect(() => {
        if (faqdata) {
            setValues({
                faqTitle: faqdata.faqTitle,
                faqDescription: faqdata.faqDescription,

            });
            if (quillRef.current) {
                quillRef.current.getEditor().root.innerHTML = faqdata.faqDescription;
            }

        }
    }, [faqdata, setValues]);

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-faq-addblogs'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <Dashboard />
                                    </div>
                                    <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                        <div className='custom-inside-addblog'>
                                            <div className='row justify-content-center align-items-center'>
                                                <div className='col-lg-10'>
                                                    <h2><a href='/dashboard/faq'><IoArrowBackCircleOutline className='me-2' style={{color:"rgb(235 138 152)"}}/></a>Edit Blogs</h2>
                                                    <div className='custom-addblogs-1'>
                                                        <form >
                                                            <div className="row mb-3">
                                                                <label htmlFor="BlogTitle" className="col-sm-3 col-form-label text-end">Blog Title</label>
                                                                <div className="col-sm-9">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control input-text-2"
                                                                        id="BlogTitle"
                                                                        name="faqTitle"
                                                                        value={values.faqTitle}
                                                                        onChange={handleChange}
                                                                        placeholder='Blog Title'
                                                                    />
                                                                       {errors.faqTitle && touched.faqTitle && <div className="text-danger">{errors.faqTitle}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <label htmlFor="BlogDescription" className="col-sm-3 col-form-label text-end">Blog Content</label>
                                                                <div className="col-sm-9 custom-edit-1">
                                                                    <ReactQuill
                                                                        className="custom-quill"
                                                                        ref={quillRef}
                                                                        value={values.faqDescription}
                                                                        onChange={(content) => {
                                                                            setFieldValue('faqDescription', content);
                                                                        }}
                                                                        style={{ width: '100%' }}
                                                                        modules={modules}
                                                                        placeholder="Write something..."
                                                                    />
                                                                    {errors.faqDescription && touched.faqDescription && <div className="text-danger">{errors.faqDescription}</div>}
                                                                </div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <button className='liquidity-btn-1 mt-4' onClick={handleSubmit}>Update</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqeditblogs