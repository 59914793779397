import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dashboard from './Dashboard';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { Spinner } from 'react-bootstrap';



function EditTrendingContent() {
    const location = useLocation();
    const { CategoryData } = location.state || {};
    
    const [category, setCategory] = useState([]);
    const { id } = useParams();
    const quillRef = useRef(null);
    const navigate = useNavigate();
    const [mainVideoPreview, setMainVideoPreview] = useState(null);
    const [loading, setLoading] = useState(false);  // Set initial loading state to false
    const fileInputRef = useRef(null);

    const { handleChange, handleSubmit, handleBlur, setFieldValue, values, setValues, errors, touched, validateForm } = useFormik({
        initialValues: {
            subCategoryId: CategoryData?.subCategoryId || '',
            subCategory: CategoryData?.subCategory || '',
            image: CategoryData?.categoryImage || ''
        },
        validationSchema: Yup.object().shape({
            subCategory: Yup.string().required('SubCategory Name is required'),
            image: Yup.mixed().required('A video file is required')// Changed to image for the file input
        }),
        onSubmit: async (values, { setFieldError }) => {
            await validateForm();
            const formData = new FormData();
            formData.append('id', id);
            formData.append('subCategoryId', values.subCategoryId);
            formData.append('subCategory', values.subCategory);
            formData.append('categoryImage', values.image);

            console.log(formData, "formDataaaaaaaaaaaaa");

            try {
                setLoading(true);
                const params = {
                    url: `updateTrendingcat`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };
                const response = await makeApiRequest(params);
                if (response.status === true) {
                    setLoading(false);
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate('/dashboard/homecontent');
                    }, 1000);
                } else {
                    toast.warn(response.message);
                }
            } catch (error) {
                toast.error('Error: ' + error.message);
            }
        }
    });

    const getCategories = async () => {
        try {
            let params = {
                url: `editTrendingcontent/${id}`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);

            if (response.status === true) {
                setCategory(response.data);
                console.log(response.data, "response.data");

            } else {
                setCategory([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getCategories();
    }, [id]);

    useEffect(() => {
        console.log("blogdata", CategoryData);

        if (CategoryData) {
            setValues({
                subCategoryId: CategoryData.subCategoryId,
                subCategory: CategoryData.subCategory,
            });

            if (CategoryData.media) {
                setMainVideoPreview(CategoryData.media); // Set the video preview
            }
        }
    }, [CategoryData, setValues]);

    const handleCategoryChange = (e) => {
        const selectedCategoryName = e.target.value;
        const selectedCategory = category.find(c => c.subCategory === selectedCategoryName);

        if (selectedCategory) {
            setFieldValue('subCategory', selectedCategory.subCategory);
            setFieldValue('subCategoryId', selectedCategory.subCategoryId); // Update subCategoryId
        }
    };


    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        
        if (file) {
            // Check if the file is a video (e.g., MP4, AVI)
            const allowedFileTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/mkv'];
            if (!allowedFileTypes.includes(file.type)) {
                toast.error('Please upload a valid video file (MP4, AVI, MOV, MKV)');
                return;
            }
    
            // Check if the file size is greater than 30MB
            const maxSizeInMB = 30;
            const fileSizeInMB = file.size / (1024 * 1024); // Convert from bytes to MB
            if (fileSizeInMB > maxSizeInMB) {
                toast.error('Video size must be less than 30MB');
                return;
            }
    
            // Preview the video file
            const reader = new FileReader();
            console.log("reader--",reader);
            
            reader.onloadend = () => {
                setMainVideoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFieldValue('image', file);
        } else {
            setMainVideoPreview(null);
        }
    };

    // Remove selected video
    const handleRemoveVideo = () => {
        setMainVideoPreview(null); // Clear video preview
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the file input
        }
        setFieldValue('image', null);
    };
    
    

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-addblog min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <div className='custom-inside-addblog'>
                                        <div className='row justify-content-center align-items-center'>
                                            <div className='col-lg-10'>
                                                <h2>Edit Trending Category</h2>
                                                <div className='custom-addblogs-1'>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mb-3">
                                                            <label htmlFor="subCategory" className="col-sm-3 col-form-label text-end">SubCategory</label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    className="form-select input-text-2"
                                                                    id="subCategory"
                                                                    name="subCategory"
                                                                    value={values.subCategory}
                                                                    onChange={(e) => handleCategoryChange(e)}  // Use custom handler
                                                                    onBlur={handleBlur}
                                                                    disabled
                                                                >
                                                                    <option value="" disabled>Select Category</option>
                                                                    {category.length > 0 ? (
                                                                        category.map((i, index) => (
                                                                            <option key={index} value={i.subCategory}>
                                                                                {i.subCategory}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="" disabled>No Categories Available</option>
                                                                    )}
                                                                </select>
                                                                {errors.subCategory && touched.subCategory && <div className="text-danger">{errors.subCategory}</div>}
                                                            </div>
                                                        </div>
                                                
                                                        <div className="row mb-3">
                                                            <label htmlFor="Image" className="col-sm-3 col-form-label text-end">SubCategory Video</label>
                                                            <div className="input-group mb-3 col-sm-9" style={{ width: '75%' }}>
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-text-2"
                                                                    id="Image"
                                                                    name="image"
                                                                    ref={fileInputRef}
                                                                    // value={values.categoryImage}
                                                                    onChange={handleFileChange}
                                                                />
                                                            </div>
                                                            {errors.image && touched.image && <div className="text-danger">{errors.image}</div>}
                                                            <div className='row'>
                                                                <label htmlFor="Image" className="col-sm-3 col-form-label text-end"></label>
                                                                <div className='image-preview mt-3 col-lg-8 ms-1'>
                                                                    {mainVideoPreview && (
                                                                        <div className='preview-container'>
                                                                            <video width="320" height="240" controls>
                                                                                <source src={mainVideoPreview} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                            <button
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={handleRemoveVideo}
                                                                            >
                                                                                <IoMdRemoveCircleOutline />
                                                                            </button>
                                                                            
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-center'>
                                                        {loading == true ? (
                                                            <button  className='liquidity-btn-1 mt-4' disabled>
                                                                <Spinner animation="border" />
                                                            </button>
                                                        ):(
                                                            <button type="submit" className='liquidity-btn-1 mt-4'>
                                                            Update
                                                        </button>
                                                        )}
                                                        </div>
                                                   
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditTrendingContent;
