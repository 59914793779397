import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import { saveAs } from 'file-saver';
import { CiSaveUp1 } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";
import { makeApiRequest } from "../AxiosServices/apiCall";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


function Userlist() {
    const [userlist, setUserlist] = useState([])
    const [showPagination, setShowPagination] = useState(true);
    // const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isListView, setIsListView] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(null);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleButtonClick = () => {
        setShowPagination(!showPagination);
    };



    const handleFilterChange = (columnId) => {
        setSelectedColumn(columnId);
    };

    const getFilteredColumns = () => {
        // console.log('selectedColumn--', selectedColumn);
        if (!selectedColumn) return columnsone;
        return columnsone.filter(col => col.id === selectedColumn);

    };

    const getUserdatas = async () => {
        try {
            let params = {
                url: `userlist`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const userData = response.data;
                setUserlist(userData);
            } else {
                setUserlist([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        getUserdatas();
    }, [])

    // filesavetype

    const exportToFile = (data, fileName, type) => {
        const blob = new Blob([data], { type });
        saveAs(blob, fileName);
    };

    const convertToJSON = (data) => JSON.stringify(data, null, 2);

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',');
        const rows = data.map(row => Object.values(row).join(',')).join('\n');
        return `${headers}\n${rows}`;
    };

    const convertToXML = (data) => {
        const xmlRows = data.map(row =>
            `<row>\n${Object.entries(row).map(([key, value]) => `  <${key}>${value}</${key}>`).join('\n')}\n</row>`
        ).join('\n');
        return `<rows>\n${xmlRows}\n</rows>`;
    };

    const convertToTXT = (data) => data.map(row => Object.values(row).join(' ')).join('\n');

    const convertToSQL = (data, tableName) => {
        const headers = Object.keys(data[0]).join(', ');
        const values = data.map(row =>
            `(${Object.values(row).map(value => `'${value}'`).join(', ')})`
        ).join(',\n');
        return `INSERT INTO ${tableName} (${headers}) VALUES\n${values};`;
    };

    const convertToExcel = (data) => convertToCSV(data);

    const generatePDF = () => {
        const doc = new jsPDF();

        // Title of the PDF
        doc.text('User List', 14, 20);

        // Define the table columns and rows
        const tableColumns = columnsone.map(col => col.name);
        const tableRows = userlist.map(row => columnsone.map(col => row[col.id]));

        // Add autoTable
        doc.autoTable({
            startY: 30,
            head: [tableColumns],
            body: tableRows,
            theme: 'grid', 
        });

        // Save the PDF
        doc.save('userlist.pdf');
    };
    const handleDownload = (format) => {
        let data;
        switch (format) {
            case 'json':
                data = convertToJSON(userlist);
                exportToFile(data, 'data.json', 'application/json');
                break;
            case 'csv':
                data = convertToCSV(userlist);
                exportToFile(data, 'data.csv', 'text/csv');
                break;
            case 'xml':
                data = convertToXML(userlist);
                exportToFile(data, 'data.xml', 'application/xml');
                break;
            case 'txt':
                data = convertToTXT(userlist);
                exportToFile(data, 'data.txt', 'text/plain');
                break;
            case 'sql':
                data = convertToSQL(userlist, 'table_name'); // Replace 'table_name' with your actual table name
                exportToFile(data, 'data.sql', 'application/sql');
                break;
            case 'excel':
                data = convertToExcel(userlist);
                exportToFile(data, 'data.csv', 'application/vnd.ms-excel');
                break;
            case 'pdf':
                // const pdfDoc = convertToPDF(userlist);
                // pdfDoc.save('data.pdf');
                generatePDF();
                break;
            default:
                break;
        }
    };

    const columnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
            sortable: true,
            width: '80px',
            id: "sno"
        },
        {
            name: 'Name',
            selector: row => row.username,
            sortable: true,
            width: "150px",
            id: "username"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '250px',
            id: "email"
        },
        {
            name: 'Phone',
            selector: row => row.mobile ? row.mobile : "--",
            sortable: true,
            width: '130px',
            id: "mobile"
        },
        {
            name: 'Address',
            selector: row => row.default_address,
            sortable: true,
            width: '500px',
            id: "default_address"
        },
    ];

    const filteredUserList = userlist.filter((user) => {
        return (
            (user.username || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.email || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.mobile || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.default_address || '').toLowerCase().includes(searchQuery.toLowerCase())
        );
    });



    const handleViewToggle = () => {
        setIsListView(!isListView);
    };

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };



    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-userlist min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='custom-blog-inside'>
                                <h2 className='fw-bold'>UserList</h2>
                                <div className='custom-blog-search d-lg-flex align-items-baseline'>
                                    <input
                                        type="text"
                                        id="inputPassword6"
                                        class="form-control input-text-2"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <div className='d-flex mt-3 mt-lg-0'>
                                        <div className='ms-3'>
                                            <button className=' custom-icon-btn-1' onClick={handleButtonClick}>
                                                {showPagination ? <FaEye /> : <FaEyeSlash />}
                                            </button>
                                        </div>
                                        <button className="custom-icon-btn-1 ms-3" onClick={handleViewToggle}>
                                            <FaListUl />
                                        </button>
                                        <div className="dropdown ms-3">
                                            <button className=" dropdown-toggle custom-icon-btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <BsLayoutTextWindowReverse />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="#" onClick={() => handleFilterChange(null)}>Show All</a>
                                                </li>
                                                {columnsone.map((col, index) => (
                                                    <li key={index}>
                                                        <a className="dropdown-item" href="#" onClick={() => handleFilterChange(col.id)}>{col.name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="dropdown ms-3">
                                            <button className=" dropdown-toggle custom-icon-btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <CiSaveUp1 />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('json')}>JSON</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('xml')}>XML</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('csv')}>CSV</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('txt')}>TXT</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('sql')}>SQL</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('excel')}>Excel</a></li>
                                                <li><a className="dropdown-item" href="#" onClick={() => handleDownload('pdf')}>PDF</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='liquidity-table-1 mt-3'>
                                <div>
                                    {isListView ? (
                                        <div>
                                            {userlist.map((row, index) => (
                                                <div key={index} style={{ marginBottom: '20px', border: '1px solid #e98d9d', padding: '10px' }}>
                                                    {getFilteredColumns().map((col, colIndex) => (
                                                        <div key={colIndex} style={{ marginBottom: '10px', display: "flex" }}>
                                                            <strong>{col.name}:</strong> {row[col.id]}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <DataTable
                                            columns={columnsone}
                                            data={filteredUserList}
                                            theme="solarized"
                                            defaultSortAsc={true}
                                            pagination={showPagination}
                                            paginationTotalRows={userlist.length}
                                            paginationPerPage={rowsPerPage}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                            onChangePage={handlePageChange}
                                            highlightOnHover
                                            dense />
                                    )}
                                </div>
                            </div>
                        </div>
                        {showButton && (
                            <button onClick={scrollToTop} id="scrollToTopBtn" title="Go to top">
                                &#8679;
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Userlist