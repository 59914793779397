import React, { useState, useEffect, useRef } from 'react';
import Dashboard from './Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoMdRemoveCircleOutline } from "react-icons/io";


function Addblog() {
    const quillRef = useRef(null);
    const Navigate = useNavigate()
    const [category, setCategory] = useState([])
    const [categoryId, setCategoryId] = useState("")
    const [loading, setLoading] = useState(false);
    const [mainImagePreview, setMainImagePreview] = useState(null);
    const [categoryName, setCategoryName] = useState("")

    const formik = useFormik({
        initialValues: {
            BlogTitle: '',
            BlogType: '',
            BlogDescription: '',
            Image: null
        },
        validationSchema: Yup.object({
            BlogTitle: Yup.string().required('*Blog Title is required'),
            BlogType: Yup.string().required('*Blog Type is required'),
            BlogDescription: Yup.string().required('*Blog Description is required'),
            Image:  Yup.mixed()
            .required('*Blog Image is required')
            .test(
                "fileSize",
                "*Image size is too large (max 2MB)",
                value => !value || (value && value.size <= 2 * 1024 * 1024) // max 2MB
            )
            .test(
                "fileFormat",
                "*Unsupported Format (allowed: jpeg, jpg, png)",
                value => value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)
            )
            
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('BlogTitle', values.BlogTitle);
            formData.append('BlogType', values.BlogType);
            formData.append('CategoryId', categoryId);
            formData.append('BlogDescription', values.BlogDescription);
            formData.append('BlogImage', values.Image);

            try {
                setLoading(true);
                const params = {
                    url: `blogUpdate`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };

                const response = await makeApiRequest(params);
                if (response.status === true) {
                    
                    toast.success(response.message);
                    setTimeout(() => {
                        Navigate('/dashboard/blogs')
                    }, 1000);
                    setLoading(false);
                } else {
                    toast.warn(response.message);
                    setLoading(false);
                }
            } catch (error) {
                toast.error('Error: ' + error.message);
                setLoading(false);
            }
        }
    });

    const getCategories = async () => {
        try {
            let params = {
                url: `getcategories`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            console.log("response", response);

            if (response.status === true) {
                const CategoryList = response.data

                setCategory(CategoryList);
                console.log('category', categoryName)


            } else {
                setCategory([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCategories();
    }, [])

    const handleQuillChange = (content, delta, source, editor) => {
        formik.setFieldValue('BlogDescription', content);
    };

    const handleMainImageChange = (event) => {
        const file = event.currentTarget.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setMainImagePreview(reader.result);
            };

            reader.readAsDataURL(file);
            formik.setFieldValue('Image', file);
        }
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    const handleRemoveImage = () => {
        setMainImagePreview(null);
        formik.setFieldValue('Image', null);

        document.getElementById('Image').value = '';
    };

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-addblog min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <div className='custom-inside-addblog'>
                                        <div className='row'>
                                            <div className='col-lg-10'>
                                                <h2><a href='/dashboard/blogs'><IoArrowBackCircleOutline className='me-2' style={{color:"rgb(235 138 152)"}}/></a> Add Blogs</h2>
                                                <div className='custom-addblogs-1'>
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className="mb-3 row">
                                                            <label htmlFor="BlogType" className="col-form-label col-sm-3 text-end">Blog Type</label>
                                                            <div className='col-sm-9'>
                                                                <select
                                                                    className="form-select input-text-2"
                                                                    id="BlogType"
                                                                    name="BlogType"
                                                                    value={formik.values.BlogType}
                                                                    onChange={(e) => {
                                                                        formik.handleChange(e);
                                                                        const selectedCategoryName = e.target.value;
                                                                        setCategoryName(selectedCategoryName);

                                                                        // Find the corresponding category ID
                                                                        const selectedCategory = category.find(cat => cat.name === selectedCategoryName);
                                                                        console.log("selectedCategory---",selectedCategory);
                                                                        
                                                                        if (selectedCategory) {
                                                                            setCategoryId(selectedCategory._id);
                                                                        } else {
                                                                            setCategoryId("");
                                                                        }
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                >
                                                                    <option value="">Choose...</option>
                                                                    {category.map((i, index) => (
                                                                        <option key={index} value={i.name}>
                                                                            {i.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {formik.touched.BlogType && formik.errors.BlogType ? (
                                                                    <div className="text-danger">{formik.errors.BlogType}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="BlogTitle" className="col-sm-3 col-form-label text-end">Blog Title</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-text-2"
                                                                    id="BlogTitle"
                                                                    name="BlogTitle"
                                                                    value={formik.values.BlogTitle}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    placeholder='Blog Title'
                                                                />
                                                                {formik.touched.BlogTitle && formik.errors.BlogTitle ? (
                                                                    <div className="text-danger">{formik.errors.BlogTitle}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="Image" className="col-sm-3 col-form-label text-end">Blog Image</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-text-2"
                                                                    id="Image"
                                                                    name="Image"
                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                    onChange={handleMainImageChange}
                                                                />
                                                                {formik.touched.Image && formik.errors.Image ? (
                                                                    <div className="text-danger">{formik.errors.Image}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className='row'>
                                                                <label htmlFor="Image" className="col-sm-3 col-form-label text-end"></label>
                                                                <div className='image-preview mt-3 col-lg-8 ms-1'>
                                                                    {mainImagePreview && (
                                                                        <div className='preview-container'>
                                                                            <img src={mainImagePreview} alt="Preview" className='img-thumbnail' />
                                                                            <button type="button" className="btn btn-danger btn-sm"
                                                                                onClick={handleRemoveImage}
                                                                            >
                                                                                <IoMdRemoveCircleOutline />
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="BlogDescription" className="col-sm-3 col-form-label text-end">Blog Content</label>
                                                            <div className="col-sm-9 custom-edit-1">
                                                                <ReactQuill
                                                                    className="custom-quill"
                                                                    ref={quillRef}
                                                                    style={{ width: '100%' }}
                                                                    value={formik.values.BlogDescription}
                                                                    onChange={handleQuillChange}
                                                                    modules={modules}
                                                                    placeholder="Write something..."
                                                                />
                                                                {formik.touched.BlogDescription && formik.errors.BlogDescription ? (
                                                                    <div className="text-danger">{formik.errors.BlogDescription}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className='text-center'>
                                                        {loading == true ? (
                                                            <button className="liquidity-btn-1" >
                                                            <Spinner animation="border" />
                                                          </button>
                                                        ) : (
                                                            <button className='liquidity-btn-1 mt-4' type="submit">Add</button>
                                                        )}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Addblog;
