import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { CiCirclePlus } from "react-icons/ci";
// import render from "../assets/images/favicon.png"
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import { saveAs } from 'file-saver';
import { CiSaveUp1 } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";
import { makeApiRequest } from "../AxiosServices/apiCall";
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

function Blog() {
    const navigate = useNavigate()
    const [showPagination, setShowPagination] = useState(true);
    const [blog, setBlog] = useState([])
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    // Handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
    };
    const handleButtonClick = () => {
        setShowPagination(!showPagination);
    };

    const [selectedColumn, setSelectedColumn] = useState(null);

    const handleFilterChange = (columnId) => {
        setSelectedColumn(columnId);
    };

    const getFilteredColumns = () => {
        // console.log('selectedColumn--', selectedColumn);
        if (!selectedColumn) return columnsone;
        return columnsone.filter(col => col.id === selectedColumn);

    };

    const getblogdatas = async () => {
        try {
            let params = {
                url: `getblog`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const blogList = response.data;
                // console.log('blogList---',blogList);
                setBlog(blogList);
            } else {
                setBlog([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getblogdatas();
    }, [])

    const convertToIST = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-IN', options);
    }

    // const handleDeleteClick = (row) => {
    //     setSelectedRow(row);
    //     setShowModal(true);
    // };

    // const confirmDelete = async () => {
    //     if (selectedRow) {
    //         await DeleteBlog(selectedRow);
    //     }
    //     setShowModal(false);
    // };

    // const handleClose = () => setShowModal(false);

    const DeleteBlog = async (row) => {
        try {
            const swalResult = await Swal.fire({
                title: "Are you sure you want to Delete?",
                icon: "question",
                iconHtml: "?",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showCancelButton: true,
                showCloseButton: true
            });
            if (swalResult.isConfirmed) {
                let datas = {
                    blogId: row._id
                }
                let params = {
                    url: `blogdelete`,
                    method: 'POST',
                    data: datas
                };
                let response = await makeApiRequest(params)
                    .then((response) => {
                        toast.success(response.message);
                        getblogdatas();
                    })
                    .catch((error) => {
                        console.log('error', error);
                    })

            }

        } catch (error) {
            toast.error("Something went wrong.");
        }
    };

    const filteredBlogs = blog.filter((b) =>
        b.blogTitle.toLowerCase().includes(searchQuery.toLowerCase())
        || b.blogDescription.toLowerCase().includes(searchQuery.toLowerCase())
        || b.blogType.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // filesavetype

    const exportToFile = (data, fileName, type) => {
        const blob = new Blob([data], { type });
        saveAs(blob, fileName);
    };

    const convertToJSON = (data) => JSON.stringify(data, null, 2);

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',');
        const rows = data.map(row => Object.values(row).join(',')).join('\n');
        return `${headers}\n${rows}`;
    };

    const convertToXML = (data) => {
        const xmlRows = data.map(row =>
            `<row>\n${Object.entries(row).map(([key, value]) => `  <${key}>${value}</${key}>`).join('\n')}\n</row>`
        ).join('\n');
        return `<rows>\n${xmlRows}\n</rows>`;
    };

    const convertToTXT = (data) => data.map(row => Object.values(row).join(' ')).join('\n');

    const convertToSQL = (data, tableName) => {
        const headers = Object.keys(data[0]).join(', ');
        const values = data.map(row =>
            `(${Object.values(row).map(value => `'${value}'`).join(', ')})`
        ).join(',\n');
        return `INSERT INTO ${tableName} (${headers}) VALUES\n${values};`;
    };

    const convertToExcel = (data) => convertToCSV(data);

    const generatePDF = () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });

        // Function to format date to '12 August 2024 at 05:50:33 pm' in IST
        const formatDateToIST = (dateString) => {
            const date = new Date(dateString);
            const options = {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
                timeZone: 'Asia/Kolkata'  // Set timezone to IST
            };
            return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', ' at');
        };

        // Title of the PDF
        doc.text('Blog', 14, 20);

        // Define the table columns and rows, excluding the 'Action' column
        const tableColumns = columnsone
            .filter(col => col.name !== 'Action') // Exclude 'Action' column
            .map(col => col.name);

        const tableRows = blog.map(row => columnsone
            .filter(col => col.name !== 'Action') // Exclude 'Action' column
            .map(col => {
                if (col.id === 'blogDescription') {
                    // Convert HTML to plain text
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(row[col.id], 'text/html');
                    return doc.body.textContent || ""; // Extract the text content
                } else if (col.id === 'createdAt') {
                    // Format date to IST
                    return formatDateToIST(row[col.id]);
                }
                return row[col.id];
            })
        );

        // Add autoTable with adjusted column widths and styles
        doc.autoTable({
            startY: 30,
            head: [tableColumns],
            body: tableRows,
            theme: 'grid', // choose 'striped', 'grid', 'plain'
            margin: { left: 10, right: 10 }, // Reduced margins to fit more content
            tableWidth: 'wrap', // Ensures table fits within page width
            styles: {
                fontSize: 10,         // Smaller font size to fit more content
                overflow: 'linebreak', // Ensures text wraps within the cell
                cellWidth: 'wrap',     // Cells are wrapped to fit content
            },
            columnStyles: {
                0: { cellWidth: 10 },  // S.no
                1: { cellWidth: 25 },  // Blog Title
                2: { cellWidth: 25 },  // Blog Type
                3: { cellWidth: 50 },  // Blog Description
                4: { cellWidth: 50 },  // Blog Image
                5: { cellWidth: 30 },  // Date
            },
        });

        // Save the PDF
        doc.save('blog.pdf');
    };




    const handleDownload = (format) => {
        let data;
        switch (format) {
            case 'json':
                data = convertToJSON(blog);
                exportToFile(data, 'data.json', 'application/json');
                break;
            case 'csv':
                data = convertToCSV(blog);
                exportToFile(data, 'data.csv', 'text/csv');
                break;
            case 'xml':
                data = convertToXML(blog);
                exportToFile(data, 'data.xml', 'application/xml');
                break;
            case 'txt':
                data = convertToTXT(blog);
                exportToFile(data, 'data.txt', 'text/plain');
                break;
            case 'sql':
                data = convertToSQL(blog, 'table_name'); // Replace 'table_name' with your actual table name
                exportToFile(data, 'data.sql', 'application/sql');
                break;
            case 'excel':
                data = convertToExcel(blog);
                exportToFile(data, 'data.csv', 'application/vnd.ms-excel');
                break;
            case 'pdf':
                // const pdfDoc = convertToPDF(blog);
                // pdfDoc.save('data.pdf');
                generatePDF();
                break;
            default:
                break;
        }
    };
    // endfilesavetype

    const columnsone = [
        { name: 'S.no', selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage, sortable: true, id: 'sno', width: '80px' },
        { name: 'Blog Title', selector: row => row.blogTitle, sortable: true, id: 'blogTitle', width: '200px' },
        { name: 'Blog Type', selector: row => row.blogType, sortable: true, id: 'blogType', width: '200px' },
        {
            name: 'Blog Description',
            selector: row => row.blogDescription,
            sortable: true,
            id: 'blogDescription',
            width: '450px',
            cell: row => <div dangerouslySetInnerHTML={{ __html: row.blogDescription }} />
        },
        {
            name: 'Blog Image',
            selector: row => {
                return (
                    <div className='custom-img-pre'>
                        <img style={{ width: "50px" }} src={row.blogImage} />
                    </div>
                )
            },
            sortable: true,
            id: 'blogImage',
            width: '100px'
        },
        { name: 'Date', selector: row => convertToIST(row.createdAt), sortable: true, id: 'createdAt', width: '250px' },
        {
            name: 'Action', selector: row => {
                return (
                    <div className='ms-1 d-flex'>
                        <p onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
                            <FaEdit className='me-2' />
                        </p>
                        <p onClick={() => DeleteBlog(row)} style={{ cursor: 'pointer' }}>
                            <RiDeleteBin6Line />
                        </p>
                    </div>
                )
            }, sortable: true, id: 'action', width: '100px'
        },
    ];

    const handleEdit = async (row) => {
        // console.log("row-----",row);
        navigate(`/editblog/${row._id}`, { state: { blogData: row } })
    }

    const [isListView, setIsListView] = useState(false);

    const handleViewToggle = () => {
        setIsListView(!isListView);
    };

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };



    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-blog min-vh-100 text-white'>
                    {/* <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this Blog?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={confirmDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal> */}
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-blog-inside'>
                                    <h2 className='fw-bold text-dark'>Blogs</h2>
                                    <div className='custom-blog-search d-lg-flex align-items-baseline'>
                                        <input
                                            type="text"
                                            id="inputPassword6"
                                            class="form-control input-text-2"
                                            aria-describedby="passwordHelpInline"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <div className='d-flex mt-3 mt-lg-0'>
                                            <div className='ms-3'>
                                                <button className=' custom-icon-btn-1' onClick={handleButtonClick}>
                                                    {showPagination ? <FaEye /> : <FaEyeSlash />}
                                                </button>
                                            </div>
                                            <button className="custom-icon-btn-1 ms-3" onClick={handleViewToggle}>
                                                <FaListUl />
                                            </button>
                                            <div className="dropdown ms-3">
                                                <button className=" dropdown-toggle custom-icon-btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <BsLayoutTextWindowReverse />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item" href="#" onClick={() => handleFilterChange(null)}>Show All</a>
                                                    </li>
                                                    {columnsone.map((col, index) => (
                                                        <li key={index}>
                                                            <a className="dropdown-item" href="#" onClick={() => handleFilterChange(col.id)}>{col.name}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="dropdown ms-3">
                                                <button className=" dropdown-toggle custom-icon-btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <CiSaveUp1 />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('json')}>JSON</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('xml')}>XML</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('csv')}>CSV</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('txt')}>TXT</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('sql')}>SQL</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('excel')}>Excel</a></li>
                                                    <li><a className="dropdown-item" href="#" onClick={() => handleDownload('pdf')}>PDF</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href='/addblog'><button className='liquidity-btn-1'>ADD Blogs</button></a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='liquidity-table-1 mt-3'>
                                    <div>
                                        {isListView ? (
                                            <div>
                                                {blog.map((row, index) => (
                                                    <div key={index} style={{ marginBottom: '20px', border: '1px solid #e98d9d', padding: '10px' }}>
                                                        {getFilteredColumns().map((col, colIndex) => (
                                                            <div key={colIndex} style={{ marginBottom: '10px', display: "flex" }}>
                                                                <strong>{col.name}:</strong> {row[col.id]}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <DataTable
                                                columns={columnsone}
                                                data={filteredBlogs}
                                                theme="solarized"
                                                defaultSortAsc={true}
                                                pagination={showPagination}
                                                paginationTotalRows={blog.length}
                                                paginationPerPage={rowsPerPage}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                                onChangePage={handlePageChange}
                                                highlightOnHover
                                                dense
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showButton && (
                            <button onClick={scrollToTop} id="scrollToTopBtn" title="Go to top">
                                &#8679;
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog