import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component'
import { useReactToPrint } from 'react-to-print';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaCheck } from 'react-icons/fa';
function Buyproduct() {

    const [orderlist, setOrderlist] = useState([]);
    const [addresslist, setAddresslist] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [gstPerc, setGstPerc] = useState("")
    const [orderDetail, setOrderDetail] = useState({
        address: "",
        amount: "",
        shippingCharge: "",
        barcode: ",",
        orderId: "",
        invoiceId: "",
        gst: "",
        product: [],
        couponDiscount: ""
    })    
    
    const [delveryDetail, setDelveryDetail] = useState({
        deliveryStatus: "",
        deliveryDate: ""
    });


    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleDeliveryStatusChange = async (_id, newStatus) => {
        try {
            const deliveryDate = newStatus === "Delivered" ? new Date().toISOString() : null;
            const params = {
                url: "addDeliveryDetails",
                method: "POST",
                data: {
                    _id,
                    deliveryStatus: newStatus,
                    deliveryDate
                }
            };

            const response = await makeApiRequest(params);
            if (response.success) {

                setOrderlist(DeliveryDetail =>
                    DeliveryDetail.map(deliveryID =>
                        deliveryID._id === _id ? { ...deliveryID, deliveryStatus: newStatus } : deliveryID
                    )
                );
                toast.success(`Delivery status updated to ${newStatus}`);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toast.warn(response.message);
            }
        } catch (error) {
            toast.error('Error: ' + error.message);
        }
    };

    const getSiteAddress = async () => {
        try {
            let params = {
                url: `getAddressSetting`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const fetchAddress = response.data[0];
                setAddresslist(fetchAddress);
            } else {
                setAddresslist([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }



    const getOrderList = async () => {
        try {
            let params = {
                url: `getorders`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const OrderData = response.data;
                setOrderlist(OrderData);

            } else {
                setOrderlist([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getsiteSetting = async () => {
        try {
            let params = {
                url: "getsiteSetting",
                method: 'GET',
            }
            let response = await makeApiRequest(params);

            if (response.status) {
                setGstPerc(response.data[0].tax_Rate)
            } else {
                setGstPerc("")
            }
        } catch (error) {
            console.log("error-", error);
        }
    }

    useEffect(() => {
        getOrderList();
        getsiteSetting();
        getSiteAddress();
    }, [])

    const handleViewClick = (element) => {
        // console.log('product--', element.product);
        setSelectedProduct(element.product);
        setIsModalOpen(true);
    };

    const convertToIST = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-IN', options);
    }

    const columnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
            sortable: true,
            width: "80px",
        },
        {
            name: 'Email',
            selector: row => row.delivery_Address.email,
            sortable: true,
            width: "300px",
        },
        {
            name: 'Name',
            selector: row => row.delivery_Address.name,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Phone',
            selector: row => row.delivery_Address.mobile,
            sortable: true,
            width: "150px",
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            width: "100px",
        },
        {
            name: 'OrderId',
            selector: row => row.orderId,
            sortable: true,
            width: "200px",
        },
        {
            name: 'OrderDate',
            selector: row => convertToIST(row.orderdate),
            sortable: true,
            width: "250px",
        },
        {
            name: 'Promo Code',
            selector: row => row.promoCode ? row.promoCode : '--',
            sortable: true,
            width: "250px",
        },
        {
            name: 'ProductName',
            cell: (row) => (
                <button
                    onClick={() => handleViewClick(row)}

                    className='liquidity-btn-1'
                >
                    View Products
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "150px",
        },
        {
            name: 'PaymentStatus',
            selector: row => row.productStatus,
            sortable: true,
            width: "150px",
            cell: row => {
                if (row.productStatus.toLowerCase() === "order confirmed") {
                    return (
                        <div style={{
                            backgroundColor: '#e98d9d',
                            padding: '5px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <FaCheck style={{ color: 'green', marginRight: '5px' }} />
                            Paid
                        </div>
                    );
                }
                return row.productStatus;
            },
        },
        {
            name: 'Delivary Status',
            selector: row => row.deliveryStatus,
            sortable: true,
            width: "150px",
            cell: row => (
                <select
                    className='custom-select-1'
                    value={row.deliveryStatus}
                    onChange={(e) => handleDeliveryStatusChange(row._id, e.target.value)}
                    disabled={row.deliveryStatus === "Delivered"}
                >
                    <option value="Pending">{row.deliveryStatus}</option>
                    {/* <option value="Pending">Pending</option> */}
                    <option value="Delivered">Delivered</option>
                </select>
            ),

        },
        {
            name: 'Delivary Date',
            selector: row => row.deliveryDate ? convertToIST(row.deliveryDate) : '-- --',
            sortable: true,
            width: "250px",
        },
        {
            name: 'Download',
            sortable: true,
            width: "200px",
            cell: (row) => (
                <div className='d-flex data-gap'>
                    <button type="button" onClick={() => handleClick(row)} className="liquidity-btn-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        View invoice
                    </button>
                </div>
            ),
        },

    ];

    const handleClick = (row) => {
        setOrderDetail({
            ...orderDetail,
            address: row.delivery_Address,
            amount: row.amount,
            shippingCharge: row.shippingCharge,
            barcode: row.barcode,
            orderId: row.orderId,
            invoiceId: row.invoiceId,
            gst: row.gstPercentage,
            product: row.product,
            couponDiscount: row.couponDiscountAmount
        });
    }

    const filteredOrder = orderlist.filter((order) =>
        order.delivery_Address?.email?.toLowerCase().includes(searchQuery.toLowerCase())
        || order.orderId?.toLowerCase().includes(searchQuery.toLowerCase())
        || order.productStatus?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: orderDetail.orderId,
    });

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProduct([]);
    };


    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-userlist'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='custom-blog-inside'>
                                <h2 className='fw-bold'>Orders</h2>
                                <div className='custom-blog-search d-lg-flex align-items-baseline'>
                                    <input
                                        type="text"
                                        id="inputPassword6"
                                        className="form-control input-text-2"
                                        aria-describedby="passwordHelpInline"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='liquidity-table-1 mt-3'>
                                <DataTable
                                    columns={columnsone}
                                    data={filteredOrder}
                                    theme="solarized"
                                    defaultSortAsc={true}
                                    pagination
                                    paginationTotalRows={orderlist.length}
                                    paginationPerPage={rowsPerPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                    onChangePage={handlePageChange}
                                    highlightOnHover
                                    dense />
                            </div>
                        </div>
                        {isModalOpen && selectedProduct && (
                            <div className="modal show d-block" tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header justify-content-end border-0 m-0 pt-0 pb-0">
                                            <button type="button" className="close" style={{ border: "none", backgroundColor: "transparent", fontSize: "20px" }} onClick={closeModal} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body border-0 pt-0">
                                            {selectedProduct?.map((detail, index) => (
                                                <div key={index}>
                                                    <div className='custom-product-table'>
                                                        <table className="table caption-top">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Price</th>
                                                                    <th scope="col">Quantity(ml or grm)</th>
                                                                    <th scope="col">Fragrance</th>
                                                                    <th scope="col">Product Quantity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{detail.productname}</td>
                                                                    <td>{detail.productdetails[0].discount_price > 0 ? detail.productdetails[0].price - detail.productdetails[0].discount_price : detail.productdetails[0].price}</td>
                                                                    <td>{detail.productdetails[0].size}</td>
                                                                    <td>{detail.productdetails[0].shade}</td>
                                                                    <td>{detail.productdetails[0].quantity}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )) || <div>No product details available.</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal fade custom-modal-invoice" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0 m-0">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Invoice</h1>
                                <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="invoice-container" ref={componentRef}>
                                    <div className="header">
                                        <div>
                                            <img src="https://res.cloudinary.com/dzsdm3mrv/image/upload/v1724733731/SkinCareLogo/newme_uwfzxb.png" alt="Company Logo" />
                                        </div>
                                        <div className="company-info">
                                            <h2>{addresslist.siteName}</h2>
                                            <p>{addresslist.address}</p>
                                            <p>{addresslist.email}</p>
                                            <p>{addresslist.mobile}</p>
                                        </div>
                                    </div>

                                    <div className="invoice-title">
                                        INVOICE
                                    </div>
                                    <img src={orderDetail.barcode} alt="Order Barcode" style={{ float: "left", marginRight: "10px", width: "33%" }} />
                                    <div className="invoice-details mb-0">
                                        <div>
                                            <p><strong>Order ID:</strong> {orderDetail.orderId} </p>
                                        </div>
                                        <div>
                                            <p><strong>Invoice ID:</strong> {orderDetail.invoiceId} </p>
                                        </div>
                                    </div>
                                    <div className="invoice-details">
                                        <div>
                                            <p><strong>Date:</strong>{moment(orderDetail.orderdate).format('L')}</p>
                                        </div>
                                        <div>
                                            <p><strong>Invoice Total:</strong> ₹{orderDetail.amount}</p>
                                        </div>
                                    </div>

                                    <div className="customer-details">
                                        <div>
                                            <p><strong>Bill To:</strong></p>
                                            <p>{orderDetail.address.name}</p>
                                            <p>{orderDetail.address.mobile}</p>
                                            <p>{orderDetail.address.email}</p>
                                        </div>
                                        <div>
                                            <p><strong>Shipping Address:</strong></p>
                                            <p>{orderDetail.address.address}</p>
                                        </div>
                                    </div>

                                    <div className='custom-product-table1'>
                                        <table className="product-table">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Unit Price</th>
                                                    <th>Quantity</th>
                                                    <th>Net Amount</th>
                                                    <th>Discount Price</th>
                                                    <th>TaxRate (GST)</th>
                                                    <th>Tax Amount</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderDetail.product.map((data, index) => (
                                                    <tr key={index}>
                                                        <td>{data.productname.toUpperCase()}</td>
                                                        <td>₹{data.productdetails[0].price}</td>
                                                        <td>{data.productdetails[0].quantity}</td>
                                                        <td>₹{data.productdetails[0].price * data.productdetails[0].quantity}</td>
                                                        <td>-₹{data.productdetails[0].discount_price > 0 ? Math.round(data.productdetails[0].discount_price * data.productdetails[0].quantity) : 0}</td>
                                                        <td>{gstPerc}%</td>
                                                        <td>₹{Math.round((data.productdetails[0].price * data.productdetails[0].quantity) * gstPerc / 100)}</td>
                                                        <td>₹{data.productdetails[0].discount_price > 0 ? Math.round((data.productdetails[0].price * data.productdetails[0].quantity)-(data.productdetails[0].discount_price * data.productdetails[0].quantity) + ((data.productdetails[0].price * data.productdetails[0].quantity)*gstPerc/100)): Math.round((data.productdetails[0].price * data.productdetails[0].quantity)+(data.productdetails[0].price * data.productdetails[0].quantity)*gstPerc/100)}</td>
                                                    </tr>
                                                ))}

                                                <tr>
                                                    <td colspan="7" ><strong>Shipping Charges:</strong></td>
                                                    <td>
                                                        ₹{orderDetail.shippingCharge}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="7" ><strong>Coupon Discount:</strong></td>
                                                    <td>
                                                        ₹{orderDetail.couponDiscount || 0}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <div className="total">
                                        <h3>Total: ₹{orderDetail.amount}</h3>
                                    </div>

                                    <div className="footer">
                                        <p>Thank you for your purchase! If you have any questions, please feel free to contact us.</p>
                                    </div>
                                </div>

                                <div className='text-center'>
                                    <button className='liquidity-btn-1' onClick={handlePrint} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
                                        Download Invoice
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buyproduct