let config = {};
let environment = "demo";
if (environment == "local") {
  config = {
    adminBackendUrl: "http://localhost:3000/admin/",
  };
}
else {
  config = {
    adminBackendUrl: "https://new-me.in/admin/"
  }

}
export default config;